import React from 'react'
import {graphql, Link} from 'gatsby'
import {Helmet} from 'react-helmet'
import Layout from '../components/Layout'
import {GatsbyImage, getSrc} from "gatsby-plugin-image";

const Page = ({data}) => {
  const mdData = data.frontmatter
  let descData = [`Spiel: ${mdData.title}`];
  if (mdData.minPlayer && mdData.maxPlayer) {
    descData.push(`Spieleranzahl: ${mdData.minPlayer}-${mdData.maxPlayer}`);
  }
  if (mdData.duration) {
    descData.push(`Spieldauer: ${mdData.duration}min`);
  }
  if (mdData.age) {
    descData.push(`Alter: ${mdData.age}+`);
  }
  if (mdData.publishYear) {
    descData.push(`Erscheinungsjahr: ${mdData.publishYear}`);
  }

  const ratingInt = parseInt(mdData.rating);
  const complexityInt = parseInt(mdData.complexity);
  const durationInt = parseInt(mdData.duration);

  const imageSrc = getSrc(mdData.featuredImage);
  return (
    <section className="section">
      <Helmet titleTemplate="%s | Spiele zu zweit">
        <title>{`Alles über ${mdData.title}`}</title>
        <meta
          name="description"
          content={descData.join(", ")}
        />
        {mdData.featuredImage ? (
          <meta property="og:image" content={imageSrc}/>) : null}
        <script type="application/ld+json">{`
                {
                "@context": "http://schema.org",
                "@type": "FAQPage",
                "mainEntity": [{
                    "@type": "Question",
                    "name": "Wie lange dauert bei ${mdData.title} eine Spielrunde?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "${durationString(mdData, durationInt)}"
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Wie schwierig ist ${mdData.title} zu verstehen?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "${complexityString(mdData, complexityInt)}"
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Mit wie vielen Spielern kann man ${mdData.title} spielen?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "${playerString(mdData)}"
                    }
                  }
                ],
                "inLanguage": "de-DE"
                }
              `}</script>
      </Helmet>
      <div className="container content" style={{maxWidth: "1024px"}}>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="columns">
              <div className="mb-5 column is-4">

                {mdData.productImage ? (<GatsbyImage image={mdData.productImage.childImageSharp.gatsbyImageData}/>) : null}
              </div>
              <div className="mb-5 mt-4 column">
                <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                  {mdData.title}
                </h1>
                <div className="is-flex pt-4">

                  <div className="has-background-primary"
                       style={{
                         "borderRadius": "64px",
                         "height": "64px",
                         "minHeight": "64px",
                         "minWidth": "64px"
                       }}><p
                    className="is-size-3 has-text-white"
                    style={{"paddingTop": "7px", "paddingLeft": "22px"}}>{ratingInt ?? "?"}</p></div>
                  <p className="ml-4 is-size-6">{mdData.description}</p>
                </div>
                {mdData.minPlayer ? (<div className="pt-4" style={{overflow:"scroll"}}>
                  <table className="table mt-4 overflow-hidden">
                    <thead>
                    <tr>
                      <th>Spieler</th>
                      <th>Spielzeit</th>
                      <th>Alter</th>
                      <th>Erscheinungsjahr</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th>{mdData.minPlayer ?? "?"}-{mdData.maxPlayer ?? "?"}</th>
                      <th>{mdData.duration ?? "?"} min</th>
                      <th>{mdData.age}+</th>
                      <th>{mdData.publishYear ?? "?"}</th>
                    </tr>
                    </tbody>
                  </table>
                </div>) : null}
                <h2>Spieltyp</h2>
                <p>{mdData.types?.map((ele) => <span
                  className="tag mr-2 is-primary">{ele}</span>) ?? "Noch nicht bekannt"}</p>
                <h2>Auszeichnungen</h2>
                <div>
                  {preiseSection(mdData)}
                </div>
              </div>
            </div>
            <div className="content">
              <h2>Wie gut ist {mdData.title} zu zweit spielbar?</h2>
              <p>Das Spiel {mdData.title} ist sehr
                vielfältig {mdData.minPlayer <= 2 ? "und kann auch" : "aber kann nicht"} mit zwei Spielern gespielt
                werden. Die Frage ist also nicht, ob man {mdData.title} zu zweit spielen kann, sondern ob
                es sich lohnt {mdData.title} für ein Spiel zu zweit zu erwerben, denn nicht jedes Brettspiel macht Spaß
                in dem etwas kleineren Format.</p>
              <p>{complexityString(mdData, complexityInt)}</p>
              <p>{durationString(mdData, durationInt)}</p>
              <p>{playerString(mdData)} Es gibt oft auch extra Regeln, die man bei {mdData.title} anwenden kann, die es
                anschließend etwas
                spannender machen.</p>
              <p>Habt ihr extra Regeln für {mdData.title}? Dann bitte sendet sie uns! Wir sind immer begierig auf
                mehr {mdData.title} Wissen!</p>
            </div>
            <div className="content">
              {weiteresSection(mdData)}
            </div>
            <div className="content">
                <a className="btn" href={"https://www.amazon.de/s?k="+mdData.id+"&tag=spielezuzweit-21"} rel="nofollow">Auf Amazon ansehen*</a>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

const complexityString = (mdData, complexityInt) => {
  if (complexityInt > 9)
    return (
      `${mdData.title} hat die Komplexität von ${complexityInt}/10, was bedeutet, ${mdData.title} ist extrem komplex und nur für erfahrende Spieler gedacht, die Lust haben sich mehrere Stunden in die Regeln zu hängen um das Spiel zu meistern.`
    )
  if (complexityInt > 7)
    return (
      `${mdData.title} hat die Komplexität von ${complexityInt}/10, was bedeutet, ${mdData.title} ist schon komplex aber nicht extrem komplex wie andere Spiele und für erfahrende Spieler geeignet. Man muss schon Lust haben sich mehrere Stunden in die Regeln einzulesen.`
    )
  if (complexityInt > 5)
    return (
      `${mdData.title} hat die Komplexität von ${complexityInt}/10 und ist von der Komplexität gutes Mittelmaß. ${mdData.title} sollte in den ersten Spielminuten verständlich sein.`
    )
  if (complexityInt > 3)
    return (
      `${mdData.title} hat die Komplexität von ${complexityInt}/10 und ist einfach und schnell zu verstehen. ${mdData.title} kann sicherlich jeder schnell verstehen und anschließend losspielen.`
    )
  if (complexityInt >= 1)
    return (
      `${mdData.title} hat die Komplexität von ${complexityInt}/10 und ist sehr einfach und schnell zu verstehen. Wir sagen ${mdData.title} ist nach 2 Minuten erklärt und der Spaß kann beginnen`
    )
  return (
    `${mdData.title} hat die Komplexität von ${complexityInt}/10. Einfacher gehts nicht.`
  )
}

const durationString = (mdData, durationInt) => {
  const startString = `Die Spieldauer ist mit ${durationInt} Minuten angegeben.`
  if (durationInt >= 60)
    return (
      `${startString} Stellt euch also auf eine lange Partie ein und bringt genügend Zeit mit (vor allem wenn ihr euch noch die Regeln durchlesen müsst).`
    )
  if (durationInt >= 45)
    return (
      `${startString} Aber darauf würde ich mich nicht verlassen. Rechnet eher mal mit einer Stunde.`
    )
  if (durationInt >= 20)
    return (
      `${startString} Ihr könnte also ein paar Runden hintereinander spielen.`
    )
  if (durationInt > 5)
    return (
      `${startString} Die Runden sind extrem schnell und ihr könnt euch für jede runde viele verschiedene Taktiken überlegen.`
    )
  return (
    `${startString} Hier ist das Motto: Ist die Runde schon vorbei? Verpasst hier auf keinen Fall das Ende denn die Runden fliegen nur so!`
  )
}
const playerString = (mdData) => {
  const minPlayer = parseInt(mdData.minPlayer);
  const maxPlayer = parseInt(mdData.maxPlayer);

  if (minPlayer === 1 && maxPlayer === 1) {
    return `Das Spiel kann sogar nur alleine gespielt werden. Verrückt oder? Aber probiert es aus! Es macht Spaß!`
  }

  let eins = "";
  if (minPlayer === 1) {
    eins = "Die Anzahl der benötigten Spieler ist einer, Yay! Das Spiel kann also auch alleine gespielt werden.";
  } else if (minPlayer === 2) {
    eins = `${mdData.title} kann auf jeden Fall zu zweit gespielt werden aber das heißt nicht unbedingt, dass es zu zweit Spaß macht! Die Frage sollte also nicht heißen, ob ${mdData.title} zu zweit spielbar ist, sondern ob es für 2 Spieler geeignet ist.`
  } else {
    eins = `Leider benötigt man für ${mdData.title} mindestens ${minPlayer} daher ist es für ein Spiel zu zweit nicht geeignet.`
  }

  let zwei = "";
  if (maxPlayer === 2) {
    zwei = `Aber das Gute ist, mit mehr als zwei Spielern kann man das Spiel auch nicht spielen. Die maximal Anzahl beträgt 2 Spieler also perfekt für die eine Partie mit zwei Spielern geeignet!`
  } else {
    zwei = `Meistens sind alle Spiele für vier oder sogar für mehr Spieler ausgelegt. ${mdData.title} kann man mit maximal ${maxPlayer} Spielern spielen.`
  }

  return `${eins} ${zwei}`
}

const preiseSection = (mdData) => {
  const elements = []
  if (mdData.nominiertSpielDesJahres > 0) {
    elements.push(<li><b>Spiel des Jahres</b>: Nominiert in {mdData.nominiertSpielDesJahres}</li>)
  }
  if (mdData.spielDesJahres > 0) {
    elements.push(<li><b>Spiel des Jahres</b>: Gewinner in {mdData.nominiertSpielDesJahres}</li>)
  }

  if (mdData.nominiertKennerSpielDesJahres > 0) {
    elements.push(<li><b>Kennerspiel des Jahres</b>: Nominiert in {mdData.nominiertSpielDesJahres}</li>)
  }
  if (mdData.kennerSpielDesJahres > 0) {
    elements.push(<li><b>Kennerspiel des Jahres</b>: Gewinner in {mdData.nominiertSpielDesJahres}</li>)
  }

  if (mdData.nominiertKinderSpielDesJahres > 0) {
    elements.push(<li><b>Kinderspiel des Jahres</b>: Nominiert in {mdData.nominiertSpielDesJahres}</li>)
  }
  if (mdData.kinderSpielDesJahres > 0) {
    elements.push(<li><b>Kinderspiel des Jahres</b>: Gewinner in {mdData.nominiertSpielDesJahres}</li>)
  }

  let withPadding = true;
  if (elements.length === 0) {
    elements.push("Noch keine Auszeichnungen bekannt")
    withPadding = false;
  }

  return <ul className={withPadding ? "" : "ml-0"}>{elements}</ul>
}
const weiteresSection = (mdData) => {
  const fragenContent = getFragenButton(mdData)
  const blogContent = getBlogButton(mdData)

  return <>
    <div className="pt-6">
      <div className="buttons">
        {fragenContent ?? ""}
        {blogContent ?? ""}
        {mdData.amazonLink ? (
          <a className="button is-outlined is-primary" href={mdData.amazonLink} rel="nofollow">Auf Amazon
            ansehen*</a>) : null}
      </div>
    </div>
  </>
}

const getFragenButton = (mdData) => {
  if (!mdData.fragenName || mdData.fragenName === "#") {
    return null
  }
  const fragenLink = `/fragen/${mdData.fragenName}`
  return <Link className="button is-primary" to={fragenLink}>6 Fragen zu {mdData.title}</Link>
}
const getBlogButton = (mdData) => {
  if (!mdData.blogpost || mdData.blogpost === "-") {
    return null
  }
  const fragenLink = `/blog/${mdData.blogpost}`
  return <Link className="button is-primary" to={fragenLink}>Blog zu {mdData.title}</Link>
}

export default function ({data}) {
  const {markdownRemark} = data
  return (
    <Layout>
      <Page data={markdownRemark}/>
    </Layout>
  )
}

export const pageQuery = graphql`
  query game($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        id
        title
        description,
        complexity,
        minPlayer,
        maxPlayer,
        duration,
        age,
        publishYear,
        rating,
        productImage {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 120, quality: 70)
          }
        }
        featuredImage {
          childImageSharp{
            gatsbyImageData(layout: FIXED, width: 1024, quality: 70)
          }        
        }
        amazonLink
        spielDesJahres,
        nominiertSpielDesJahres,
        kennerSpielDesJahres,
        nominiertKennerSpielDesJahres,
        kinderSpielDesJahres,
        nominiertKinderSpielDesJahres
        fragenName
        blogpost
        types
        abstractStrategyGame
        customizableGame
        thematicGame
        familyGame
        childrenGame
        partyGame
        strategyGame
        wargame
      }
    }
  }
`